import React from "react"
import styled from "@emotion/styled"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import Container from "../components/container"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MailerliteModal from "../components/MailerliteModal"
import TestimonialCarousel from '../components/testimonialCarousel'

const testimonials = [
  {
    name: 'Sergei Guk',
    role: 'Laravel backend developer',
    image: '/images/avatar-sergei.jpg',
    linkedin: 'https://www.linkedin.com/in/sergei-guk-239a7a59/',
    text: (
      <>
        This course is a great way to break from a tutorial hell into <b>developing projects completely on your own</b>.<br /><br />
        <b>Thorough code reviews</b> from an experienced instructor and <b>real-world workflow</b> (using git, group chat, project management, and design apps) help you boost your React knowledge as you are developing this nice little project completely from scratch making all architectural decisions.<br /><br />
        Johannes is an awesome instructor and overall I highly recommend his course!
      </>
    ),
  },
  {
    name: 'Martin Kruger',
    role: 'Self-taught React developer',
    image: '/images/avatar-martin.jpg',
    text: (
      <>
        ooloo.io <b>taught me in 2 weeks what would have probably taken me months</b> to learn on my own. Finding work experience is difficult and this course does a good job of getting you closer to getting this experience.<br/><br/>
        It also covers <b>content you do not find elsewhere</b>, such as pixel-perfect designs. Highly recommended!
      </>
    ),
  },
  {
    name: 'Ayoub Idelkanoun',
    role: 'Self-taught React developer',
    image: '/images/avatar-ayoub.jpg',
    website: 'https://www.ayoubkanoun.com/',
    text: (
      <>
        This course made me feel more <b>confident to start applying for jobs</b>. Before I started this course I didn't know <b>Agile methodology, Github branches, or pixel-perfect layouts</b>.<br /><br />
        It's great to get a code review after finishing a task. That helped me improve a lot. I surely recommend this course to anyone who wants to <b>know how real-world engineering teams work</b>.
      </>
    ),
  }
]

const HeroImage = styled(BackgroundImage)`
  height: 500px;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
`
 const HeroHeadline = styled.h1`
  color: white;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.4);

  @media(max-width: 520px) {
    font-size: 45px;
    line-height: 52px;
  }
 `

const Image = styled(Img)`
  margin: 60px -60px;
  width: calc(100% + 120px);

  @media(max-width: 630px) {
    width: calc(100% + 40px);
    margin: 60px -20px;
  }
`

const PortraitWrapper = styled.div`
  width: 200px;
  height: 200px;
  margin: 40px 30px 30px;
  border-radius: 50%;
  overflow: hidden;
  float: right;
`

const IndexPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "professional-developer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jobListing: file(relativePath: { eq: "job-listing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kanban: file(relativePath: { eq: "kanban.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      designs: file(relativePath: { eq: "designs.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pr: file(relativePath: { eq: "pr.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eslint: file(relativePath: { eq: "eslint.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      portrait: file(relativePath: { eq: "johannes.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const params = new URLSearchParams(props.location.search)
  const isCheckout = params.get('checkout')

  const showCheckout = () => {
    const email = params.get('email')
    const name = params.get('name')
    const coupon = params.get('coupon')
    const github = params.get('github')

    const passthrough = JSON.stringify({
      github,
      email,
      name,
    })

    // eslint-disable-next-line no-undef
    Paddle.Checkout.open({
      product: 592859,
      coupon,
      email,
      passthrough,
      allowQuantity: false,
    })
  }

  return (
    <Layout>
      <SEO title={`"Am I job-ready for web development?"`} />

      <HeroImage fluid={[
        `linear-gradient(rgba(117, 176, 156, 0.5), rgba(117, 176, 156, 0.5))`,
        data.backgroundImage.childImageSharp.fluid,
      ]}>
        <Container>
          <HeroHeadline>"Am I job-ready for web development?"</HeroHeadline>
        </Container>
      </HeroImage>

      <Container>
        <p>
          For most of us, our career in web development starts with tutorials. <b>Lots
          of tutorials.</b>
        </p>
        <p>
          We follow a Udemy course or two that promise we will become a Senior React
          dev in 40hrs for $12.99 on "discount"! <span role="img" aria-label="rocket">🚀</span>
        </p>
        <p>
          We follow the advice of practically everyone on the internet: "Build something".
        </p>
        <p>
          We end up with a half-baked Todo app and maybe yet another YouToob. <span role="img" aria-label="face-with-raised-eyebrow">🤨</span>
        </p>
        <p>
          By now you feel comfortable with HTML, CSS, and JavaScript. Perhaps even React, Vue, or Angular.
          At least kind of...
        </p>

        <h2>But is that enough to get you a job?</h2>
        <p>
          You already have the feeling that this is not all that's required to call yourself a
          professional developer. And you're right!
        </p>
        <p>Let's have a look at a typical job listing for a frontend web developer:</p>
        <Image fluid={data.jobListing.childImageSharp.fluid} />
        <p>Yikes! Let's be honest: you can check off the top two. At least if you leave out the "proficient"... But what about the rest?</p>

        <h2>How are you supposed to have professional experience and know agile team workflows without a prior job??</h2>
        <p>Catch-22, right? All those tutorials and courses focus on one thing: writing code.</p>
        <p>But what about</p>
        <ul>
          <li>Git, pull requests, and code reviews?</li>
          <li>working with real pixel-perfect designs?</li>
          <li>agile team workflows?</li>
          <li>working in the scope of tickets in a project management system?</li>
        </ul>
        <p>There seems to be so much for you to learn. At the same time, you need to find a job soon...</p>
        <p>Enough of this horror scenario. Let's take a step back.</p>

        <h2>
          What if you knew how to work as a professional developer before starting
          your first job?
        </h2>
        <p>
          Even better: what if you could experience it first hand{" "}
          <b>while working on a real-world project</b>?
        </p>
        <p>
          You would learn tons about <b>professional workflows and tools</b>. And
          in the end, you would have something{" "}
          <b>exceptional to put on your resume or portfolio</b>. 🤩
        </p>
        <p>
          You could add new skills to your CV and feel much more comfortable in
          job interviews. Once you find a job your onboarding would be like a
          breeze. You could start working like a fellow professional right away.
        </p>
        <p>But again: catch-22! How could you get experience in all those things without landing your first dev job?</p>

        <h2>Implement a real-world project like you would in a professional team with a React veteran reviewing every line of your code!</h2>
        <p>This is what's awaiting you once you enroll:</p>
        <ul>
          <li>Implement a <b>complete React app</b> just like you would in a <b>professional team</b></li>
          <li>Write <b>pixel-perfect layouts</b> according to <b>designs made by UI-experts</b></li>
          <li><b>Kanban workflow</b> with a commonly used project management tool</li>
          <li><b>Professional Git workflow</b> on GitHub with pull requests and CI pipelines</li>
          <li><b>Code reviews for every pull request</b> by a professional developer with years of React experience</li>
          <li><b>A new project for your portfolio</b> that's 10x better than any todo or weather app</li>
        </ul>
        <p>You can work at your own pace to fit this course into your schedule. The project has a medium complexity. It will at least take a week to implement it depending on your experience.</p>
        <p>All this combined will help you stand out from the crowd of job applicants and make you an attractive candidate.</p>
        <p>
          Simply sign up to the waitlist below and get notified once the next
          batch starts.
        </p>

        <MailerliteModal
          isCheckout={isCheckout}
          showCheckout={showCheckout}
        />

        <h2 id="testimonials">Don't only take my word on it. These developers already enrolled.</h2>
        <TestimonialCarousel testimonials={testimonials} />

        <h2>This is how the course will work</h2>
        <p>Since we focus on software development in a professional environment <b>this course is not a good fit for beginners.</b></p>
        <p>Maybe you have some experience with React already, but pull requests, Kanban, or working with designs are new to you. That's completely fine.</p>
        <p>But you should at least have a somewhat solid knowledge of JavaScript and React.</p>
        <p>You're probably on a good level for this course if you have implemented a couple of small apps on your own like a todo or weather app with a bit of state management and data fetching.</p>
        <p><b>The course will take around 2 weeks</b> depending on your experience and the time you can invest.</p>
        <p><b>We will offer code reviews for 4 weeks</b> so you have enough time to implement the project. If you need more time for any reason you can reach out to us, of course.</p>
        <p>If you're in doubt whether your skills or available time are sufficient don't hesitate to <a href="mailto:course@ooloo.io">contact us</a>.</p>
        <p>Once you enrolled you'll get invites to following tools</p>
        <ul>
          <li><b>Notion</b> as a knowledge base</li>
          <li><b>Asana</b> as a project management tool</li>
          <li><b>Zeplin</b> as a design tool</li>
          <li>a <b>GitHub</b> repository for your code</li>
          <li>a <b>Slack</b> group for our communication</li>
        </ul>
        <p>These tools contain all the information you need to implement the project.</p>
        <p>We prepared a knowledge base for you where you can read up on different techniques used in the course. You will be guided through your first tasks and we explain everything step by step.</p>
        <p>But soon you'll take the training wheels off and implement the tasks independently. No worries though, we'll stay in close contact via Slack throughout the course.</p>
        <p>Read the next sections for more information about the tools and what you'll learn.</p>

        <h2>Kanban and Asana</h2>
        <p>Project management is an integral part of any software company. That's why we use Asana to teach you Kanban as a modern agile workflow.</p>
        <Image fluid={data.kanban.childImageSharp.fluid} />
        <p>All tasks are written as user stories with detailed acceptance criteria.</p>
        <p>Many developers are not used to thinking of features in the scope of tasks at the beginning of their careers. They touch multiple features within one pull request.</p>
        <p>With well-defined tasks and code reviews, you learn a structured way of feature-driven software development.</p>

        <h2>Pixel-perfect designs and Zeplin</h2>
        <p>One of the main tasks of every frontend developer is translating designs into functioning code. We provide professional designs created by a UI-expert. They are made with Sketch and hosted on Zeplin.</p>
        <Image fluid={data.designs.childImageSharp.fluid} />
        <p>Creating pixel-perfect designs with CSS is another story. You learn relevant techniques that make you a valuable asset for any design-focused product.</p>

        <h2>PRs and GitHub</h2>
        <p>You learn a professional workflow with GitHub, pull requests, and code reviews.</p>
        <Image fluid={data.pr.childImageSharp.fluid} />
        <p>Most developers who lack professional team experience also lack practical knowledge of working with Git. But in a team setting, you can't work on the master branch anymore.</p>
        <p>You learn how to work with Git and pull requests. Branching, squash merging, and rebasing won't be new to you.</p>

        <h2>Team rules and ESLint</h2>
        <p>Every team has its own rules. And every developer needs to play by them.</p>
        <Image fluid={data.eslint.childImageSharp.fluid} />
        <p>We use ESLint rules and CI pipelines that help you format your code.</p>

        <MailerliteModal
          isCheckout={isCheckout}
          showCheckout={showCheckout}
        />

        <PortraitWrapper>
          <Img fluid={data.portrait.childImageSharp.fluid} />
        </PortraitWrapper>

        <h2>The course instructor</h2>
        <p>
          Hi, I'm Johannes, a self-taught software developer with 10+ years of
          experience. I worked in different areas from scientific computing to
          mobile and finally full-stack web development.
        </p>
        <p>
          About 5 years ago I started developing with React and immediately fell
          in love. Since then I worked in dynamic startups and established
          corporations with teams of different sizes.
        </p>
        <p>
          I know what it means to learn all this stuff the hard way: on the job
          when you have to get used to an unknown code base, new team members,
          company structures. This is not easy and can mean a lot of stress.
        </p>
        <p>
          Hopefully, with this course, you'll be able to easily build a career in
          web development as well as have a great onboarding on your first job.
        </p>
      </Container>
    </Layout>
  )
}

export default IndexPage
